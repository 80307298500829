import React, { useState } from "react";
import { useForm } from "react-hook-form";

import addToMailchimp from "gatsby-plugin-mailchimp";

const InsightPageSub = () => {
  const { register, handleSubmit } = useForm();

  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = (data) => {
    const { email } = data;
    addToMailchimp(email, { TYPE: "Insight Subscription" }).then((data) => {
      console.log(data);
      setShowMessage(true);
    });
  };
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="insight-page-sub bg-light-blue">
          <div className="title">Sign Up</div>
          <div className="para m-top-10px">
            To keep yourself updated on Goken news
          </div>
          {showMessage ? (
            <div>Thank you for subscribing to Goken news</div>
          ) : (
            <form className="semi-para" onSubmit={handleSubmit(onSubmit)}>
              <input
                name="email"
                type="email"
                placeholder="Email*"
                {...register("email", { required: true })}
              />
              <input
                className="btn-sub m-top-20px"
                type="submit"
                value="Subscribe"
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightPageSub;
